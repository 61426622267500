import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {NewTodoModal} from '../components/new-todo-modal';
import {TodoView} from '../components/todo-view';
import {useTodoLogic} from '../utils/todo-logic';

export const TodoScreen: React.FC = () => {
    const {
        todos,
    } = useTodoLogic();

    const [showModal, setShowModal] = useState(false);

    const showNewTodoModal = () => {
        setShowModal(true);
    };

    const hideNewTodoModal = () => {
        setShowModal(false);
    };

    if (!todos.values)
        return (<></>);

    return (
        <div id="todo-screen" className="px-2">
            <NewTodoModal show={showModal} handleClose={hideNewTodoModal} />

            <div className="text-center mt-3 mb-3">
                <Button onClick={() => showNewTodoModal()}>New Goal! 😻</Button>
            </div>

            {todos.values.map(todo => (
                <TodoView key={todo.id!} todo={todo} />
            ))}
        </div>
    );
};
