import React, {useMemo, useEffect} from 'react';
import nl2br from 'react-nl2br';
import Card from 'react-bootstrap/Card';
import ReactTimeAgo from 'react-time-ago';
import {useTodoLogic} from '../utils/todo-logic';
import {Todo} from '../utils/types';

const dummyDate = new Date(2022, 1, 1);

type TodoViewProps = {
    todo: Todo,
};

export const TodoView: React.FC<TodoViewProps> = props => {
    const {
        todo,
    } = props;

    const {
        userIdToName,
    } = useTodoLogic();

    const authorName = useMemo(() => {
        return userIdToName(todo.author);
    }, [todo.author]);

    const todoDate = useMemo(() => {
        if (!todo.date)
            return;

        return todo.date.toDate();
    }, [todo.date]);

    if (!todoDate)
        return (<></>);

    return (
        <Card className="mb-2">
            <Card.Header>{authorName}</Card.Header>
            <Card.Body>
                <Card.Text className="fs-5">{nl2br(todo.text)}</Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">
                <ReactTimeAgo date={todoDate} />
            </Card.Footer>
        </Card>
    );
};
