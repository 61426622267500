import {DocumentReference, FieldValue, Timestamp} from "firebase/firestore";

export type UserId = 0 | 1;

export enum UserEmails {
    Marian = 'marianlove@mariansam.eu',
    Greta = 'gretalove@mariansam.eu',
}

export enum UserIds {
    Marian = 0,
    Greta = 1,
}

export enum Users {
    Marian = 'Marian ❤',
    Greta = 'Gréta 🖤',
}

export type FirestoreDocument = {
    id?: string,
    ref?: DocumentReference<any>,
};

export type Todo = FirestoreDocument & {
    text: string,
    author: UserId,
    date: Timestamp,
};
