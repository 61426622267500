import React, { useState, useEffect, useMemo } from 'react';
import { useCollectionData, useCollection, useDocumentData } from 'react-firebase-hooks/firestore';

import { addDoc, collection, deleteDoc, doc, DocumentReference, getDoc, orderBy, Query, query, serverTimestamp, setDoc } from 'firebase/firestore';

import { firestore } from './firebase';

import {genericFirestoreConverter} from './generic-converter';

// i'm proud of this shit
export const useFirebaseCollection = <T>(collectionName: string) => {
    const theCollection = collection(firestore, collectionName).withConverter(genericFirestoreConverter);

    const [values, loading, error] = useCollectionData(theCollection as Query<T>);

    return { values, loading, error };
};

// i'm proud of this shit
export const useFirebaseCollectionOrdered = <T>(collectionName: string, orderRule: string) => {
    const theCollection = collection(firestore, collectionName).withConverter(genericFirestoreConverter);
    const theQuery = query(theCollection, orderBy(orderRule));

    const [values, loading, error] = useCollectionData(theQuery as Query<T>);

    return { values, loading, error };
};

// i'm proud of this shit
export const useFirebaseCollectionOrderedDesc = <T>(collectionName: string, orderRule: string) => {
    const theCollection = collection(firestore, collectionName).withConverter(genericFirestoreConverter);
    const theQuery = query(theCollection, orderBy(orderRule, 'desc'));

    const [values, loading, error] = useCollectionData(theQuery as Query<T>);

    return { values, loading, error };
};

// i'm proud of this shit
export const useFirebaseDocument = <T>(collectionName: string, documentName: string) => {
    const theDoc = doc(firestore, collectionName, documentName).withConverter(genericFirestoreConverter);

    const [value, loading, error] = useDocumentData<T>(theDoc as DocumentReference<T>);

    return { value, loading, error };
};

// i'm proud of this shit
export const useFirebaseDocumentByReference = <T>(docRef: DocumentReference<T> | undefined) => {
    const theDoc = docRef?.withConverter(genericFirestoreConverter);

    const [value, loading, error] = useDocumentData<T>(theDoc as DocumentReference<T>);

    return { value, loading, error };
};
