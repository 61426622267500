import React, { useState, useRef } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { Button, FormControl, InputGroup } from 'react-bootstrap';
import {auth} from '../utils/firebase';

const GIFURL = 'https://i.giphy.com/media/3oriO0OEd9QIDdllqo/giphy.webp';

const login = (user: string, pass: string) => {
    signInWithEmailAndPassword(auth, user, pass);
};

export const LoginScreen: React.FC = () => {
    const [password, setPassword] = useState("");

    const userInputRef = useRef<HTMLSelectElement | null>();

    const clickLogin = () => {
        console.log('loggingin', userInputRef.current!.value, password);
        login(userInputRef.current!.value, password);
    };

    return (
        <div className='p-4 mx-auto'>
            <div className="text-center">
                <img src={GIFURL} className='mw-100 mb-3'></img>
            </div>

            <select ref={ref => userInputRef.current = ref} style={{height: 50, width: '100%'}}>
                <option value="marianlove@mariansam.eu">Marian ❤</option>
                <option value="gretalove@mariansam.eu">Gréta 🖤</option>
            </select>

            <FormControl className='my-2 py-2' placeholder='Heslo' type="password" onChange={(e) => setPassword(e.target.value)} />
            <Button className='mx-auto' onClick={() => clickLogin()} style={{display: "block"}}>Login</Button>
        </div>
    );
};
