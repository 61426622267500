import React, { useState, useEffect, useMemo } from 'react';
import { useCollectionData, useCollection, useDocumentData } from 'react-firebase-hooks/firestore';

import { addDoc, collection, deleteDoc, doc, DocumentReference, getDoc, orderBy, Query, query, serverTimestamp, setDoc, Timestamp } from 'firebase/firestore';

import { auth, firestore } from './firebase';
import { useFirebaseCollection, useFirebaseCollectionOrderedDesc } from './fb-utils';
import { Todo, UserEmails, UserId, UserIds, Users } from './types';

import {genericFirestoreConverter} from './generic-converter';
import {useAuthState} from 'react-firebase-hooks/auth';

export const useTodoLogic = () => {
    const [user, loading, error] = useAuthState(auth);

    const todos = useFirebaseCollectionOrderedDesc<Todo>('todos', 'date');

    const getCurrentUserId = () => {
        if (!user)
            return UserIds.Marian;

        if (user.email === UserEmails.Marian)
            return UserIds.Marian;
        else if (user.email === UserEmails.Greta)
            return UserIds.Greta;

        return UserIds.Marian;
    };

    const userIdToName = (userId: UserId) => {
        if (userId === 0)
            return Users.Marian;
        else if (userId === 1)
            return Users.Greta;

        return 'YO';
    };

    const createTodo = async (todoText: string) => {
        const newTodo: Todo = {
            text: todoText,
            author: getCurrentUserId(),
            date: serverTimestamp() as Timestamp,
        };

        const newTodoRef = await addDoc(collection(firestore, 'todos'), newTodo);

        console.log({newTodoRef});
    };

    return {
        todos,

        getCurrentUserId,
        userIdToName,
        createTodo,
    };
};
