import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {useTodoLogic} from '../utils/todo-logic';

type NewTodoModalProps = {
    show: boolean,
    handleClose: () => void,
};

export const NewTodoModal: React.FC<NewTodoModalProps> = props => {
    const {
        show,
        handleClose,
    } = props;

    const {
        createTodo,
    } = useTodoLogic();

    const todoTextRef = useRef<HTMLTextAreaElement>();

    const acceptTodo = async () => {
        console.log({todoTextRef});
        await createTodo(todoTextRef.current!.value);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>New Goal 😻</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Control
                            ref={(ref: any) => todoTextRef.current = ref}
                            as="textarea"
                            placeholder="New goal text vole"
                            rows={3}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Ne 😠
                </Button>
                <Button variant="primary" onClick={acceptTodo}>
                    Jeeejj 🎉
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
