import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue} from "firebase/firestore";

export const genericFirestoreConverter: FirestoreDataConverter<any> = {
    toFirestore(data: WithFieldValue<any>): DocumentData {
        const { id, ref, ...rest } = data;
        return rest;
    },

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): any {
        const data = snapshot.data(options);

        return {
            id: snapshot.id,
            ref: snapshot.ref,
            ...data,
        };
    },
};

// const ref = collection(firestore, 'posts').withConverter(postConverter);
// const [data, loading, error] = useCollectionData(ref);
