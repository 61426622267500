// You might think that Firebase should be initilized as a singleton (and that's correct)
// and that importing this file in every file that needs Firebase will create multiple instances (that's wrong).
// ES6 modules (import export keywords) are always initilized once and then... idk how it works
// But see the debug console.log below


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// debug:
console.log('initilizing firebase!');
// it's printed only once

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZ48zsrdco75qwXRp5EILQAF-H-gSffZQ",
  authDomain: "love-25fcb.firebaseapp.com",
  projectId: "love-25fcb",
  storageBucket: "love-25fcb.appspot.com",
  messagingSenderId: "609140541962",
  appId: "1:609140541962:web:4fa5747d95743330a1edf3",
  measurementId: "G-RK7GHKPJQY"
};


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

// Initialize Cloud Firestore and get a reference to the service
const firestore = getFirestore(firebase);
const auth = getAuth(firebase);

export {
    firebase,
    analytics,
    firestore,
    auth,
};
