import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import {auth, firebase} from './utils/firebase';
import {LoginScreen} from './screens/login';
import {TodoScreen} from './screens/todo';
import {signOut} from 'firebase/auth';

const logout = () => {
    signOut(auth);
}

console.log(logout);

const App: React.FC = () => {
    const [user, loading, error] = useAuthState(auth);

    return (
        <div className="App">
            <h1 className="fw-bold text-center my-2">❤ Couple Goals List ❤</h1>

            {!user && !loading && (
                <LoginScreen />
            )}

            {user && (
                <TodoScreen />
            )}
        </div>
    );
}

export default App;
